import React from "react"

import Layout from "../../components/layout"
import WordPress from "../../components/screens/Skill/WordPress"
import { Helmet } from "react-helmet"

const WordpressPage = () => (
  <Layout pageInfo={{ pageName: "WordPress" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>WordPress</title>
        <meta name="description" content="WordPress" />
    </Helmet>
    <WordPress />
  </Layout>
)

export default WordpressPage
