import React, { Component } from "react"
import { Image } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-gtag'

class WordPress extends Component {
  render() {
    return (
      <div className="wrapper-skill">
        <Image
          alt="900x500"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
          data-src={require("../../../../images/skill/wordpress.jpg")}
          className="bannerSkill lazyload"
        />
        <div className="tab-skill">
          <OutboundLink to="/capabilities/php-mysql" activeClassName="active">Php/mysql</OutboundLink>
          <OutboundLink to="/capabilities/jquery-ajax" activeClassName="active">Jquery/Ajax</OutboundLink>
          <OutboundLink to="/capabilities/magento" activeClassName="active">Magento</OutboundLink>
          <OutboundLink to="/capabilities/joomla" activeClassName="active">Joomla</OutboundLink>
          <OutboundLink to="/capabilities/wordpress" activeClassName="active">WordPress</OutboundLink>
          <OutboundLink to="/capabilities/html-css" activeClassName="active">Css3/html5</OutboundLink>
          <OutboundLink to="/capabilities/pts-illus" activeClassName="active">Phptoshop/Illustrator</OutboundLink>
          <OutboundLink to="/capabilities/nginx" activeClassName="active">Apache/Nginx</OutboundLink>
          <OutboundLink to="/capabilities/varnish" activeClassName="active">Varnish</OutboundLink>
        </div>
        <div className="content-skill">
          <h1 className="title-page-skill">WordPress</h1>
          <div>
            <div className="left-content-skill">
              <p>We have built over 100 custom themes and plugins for clients of all shapes and sizes. Whether you need a simple website developed or an advanced application built for WordPress, we can add tremendous value to your business.
If you are in need of enterprise WordPress consulting, we can work together to create enterprise solutions.</p>
              <p>Our WordPress Development Services</p>
              <p>Theme Development<br/>WordPress Theme Developer</p>
            </div>
            <div className="right-content-skill">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/content-wordpress.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
          <br/>
          <div>
            <div className="left-content-skill reverse">
              <p>We offer expert WordPress theme development solutions that bring incredible value to your business. The price and development time of the project is determined by the complexity of the design and the needs of your business. Mobile responsive theme development is also offered if it fits the needs of your business.</p>
              <p>Don’t have a design for your site? I can put you in touch with one of my preferred design partners to craft a WordPress solution perfect for your project needs.</p>
            </div>
            <div className="right-content-skill reverse">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/content-wordpress-2.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
          <br/>
          <div>
            <div className="left-content-skill">
              <p>Plugin Development<br/>WordPress Plugin Developer</p>
              <p>Does your website require more advanced customization suitable for a WordPress plugin? We can develop a tailored WordPress plugin that fits the bill perfectly for your project. From integrating with your proprietary systems to creating custom e-commerce applications, We can build rock-solid and secure plugins to manage your advanced website needs.</p>
            </div>
            <div className="right-content-skill">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/content-wordpress-3.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
          <br/>
          <div>
            <div className="left-content-skill reverse">
              <p>Code Auditing<br/>WordPress Code Auditing</p>
              <p>Every WordPress application should be audited for efficiency, reliability and security. We write secure and reliable code for commercial WordPress products, and we am happy to offer my auditing services to ensure that your WordPress project meets WordPress coding standards, performs under high stress situations and secures your website from potential hackers.</p>
            </div>
            <div className="right-content-skill reverse">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/content-wordpress-4.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WordPress;
